import { useDispatch } from 'react-redux'
import { Formik, Form, Field, FormikHelpers } from 'formik'
import { Entity } from '@mpe/api-client/models/entities/entity'
import { Audio } from '@mpe/api-client/models/entities/audio'
import { Video } from '@mpe/api-client/models/entities/video'

import { hide } from '@/store/slices/modalSlice'
import {
  createAudioPlaylist,
  updateEntityAudioPlaylistsRelation
} from '@/store/thunks/audioPlaylistsThunk'
import {
  createVideoPlaylist,
  updateEntityVideoPlaylistsRelation
} from '@/store/thunks/videoPlaylistsThunk'
import { errorMessages } from '@/consts'

import Button from '../Button'
import Icon from '../Icon'
import Input from '../Input'
import playlistModalStyles from '../PlaylistModal/playlistModalStyles.module.css'
import { VideoPlaylist } from '@mpe/api-client/models/video-playlist'
import { AudioPlaylist } from '@mpe/api-client/models/audio-playlist'
import { AppThunk } from '@/store/index'

interface PlaylistFormValues {
  name: string
  isPublic: false
}

export enum PlaylistType {
  Audio,
  Video
}

interface NewPlaylistModalProps {
  entity?: Entity<Audio | Video>
  type: PlaylistType
  onPlaylistCreated?: any
}

const NewCustomPlaylistModal = ({ entity, type, onPlaylistCreated }: NewPlaylistModalProps) => {
  const dispatch = useDispatch()
  const dispatchAsync = useDispatch<(thunk: AppThunk) => Promise<any>>()

  const initialValues: PlaylistFormValues = {
    name: '',
    isPublic: false
  }

  const validateName = (value: string) => {
    return !value ? errorMessages.requiredField : ''
  }

  const hideModal = () => {
    dispatch(hide())
  }

  const onSubmit = async (
    { name, isPublic }: PlaylistFormValues,
    { setSubmitting, resetForm }: FormikHelpers<PlaylistFormValues>
  ) => {
    if (type === PlaylistType.Audio) {
      const { playlist, playlists } = await dispatchAsync(createAudioPlaylist(name, isPublic))
      const playlistIds: String[] = playlists
        .filter((p: AudioPlaylist) =>
          p.tracks.some((track) => track.audio.id === (entity as Audio).id)
        )
        .map((p: AudioPlaylist) => p['@id'])

      entity &&
        (await dispatchAsync(
          updateEntityAudioPlaylistsRelation(
            [...playlistIds, playlist['@id']],
            (entity as Audio).id
          )
        ))
    }

    if (type === PlaylistType.Video) {
      const { playlist, playlists } = await dispatchAsync(createVideoPlaylist(name, isPublic))
      const playlistIds: String[] = playlists
        .filter((p: VideoPlaylist) =>
          p.tracks.some((track) => track.video.id === (entity as Video).id)
        )
        .map((p: VideoPlaylist) => p['@id'])

      entity &&
        (await dispatchAsync(
          updateEntityVideoPlaylistsRelation(
            [...playlistIds, playlist['@id']],
            (entity as Video).id
          )
        ))
    }

    hideModal()
    resetForm()
    setSubmitting(false)
    onPlaylistCreated && onPlaylistCreated()
  }

  return (
    <div className="flex flex-col w-full overflow-hidden shadow-lg">
      <div className="flex w-full p-5 h-20 items-center bg-gradient-to-tr from-tomato to-indigo">
        <h4 className="text-xl font-bold">Új lejátszási lista hozzáadása</h4>
        <Icon
          name="plus"
          className="rotate-45 ml-auto cursor-pointer"
          onClick={() => dispatch(hide())}
        />
      </div>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        <Form className="flex flex-col p-5 bg-black">
          <Field component={Input} type="text" name="name" validate={validateName} label="Cím" />
          <label className="mt-3 text-center">
            <span className="align-text-bottom mr-2">Nyilvános</span>
            <Field
              type="checkbox"
              name="isPublic"
              className={`${playlistModalStyles.customCheckbox} opacity-0 absolute h-8 w-8`}
            />
            <div className="bg-white border-2 w-6 h-6 inline-flex shrink-0 justify-center items-center align-text-bottom">
              <Icon
                name="check-mark"
                className={`hidden fill-current w-3 h-3 pointer-events-none ${playlistModalStyles.customCheckboxIcon}`}
              />
            </div>
          </label>
          <Button
            type="submit"
            className="mt-5 mx-auto w-40 bg-gradient-to-b from-tomato to-indigo hover:from-indigo hover:to-tomato"
          >
            Hozzáad
          </Button>
        </Form>
      </Formik>
    </div>
  )
}

export default NewCustomPlaylistModal
