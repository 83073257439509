import { ErrorMessage, getIn, FieldInputProps, FormikProps } from 'formik'

interface InputProps {
  label: string
  type: string
  field: FieldInputProps<any>
  form: FormikProps<any>
  className?: string
  disabled?: boolean
}

const Input = ({
  label,
  type,
  field,
  form: { errors, touched },
  className,
  disabled,
  ...props
}: InputProps) => {
  const getBorderColor = (errors: any, fieldName: string): string => {
    return getIn(errors, fieldName) && getIn(touched, fieldName) ? 'border-red' : 'border-white'
  }
  const isCheckbox = type === 'checkbox'

  const checkboxComp = isCheckbox && (
    <>
      <div className={`${className} flex flex-row gap-1`}>
        <input
          {...field}
          type="checkbox"
          id={field.name}
          name={field.name}
          className={`scale-125 transform-gpu mr-2 ${getBorderColor(errors, field.name)}`}
          disabled={disabled}
          {...props}
        />
        <label htmlFor={field.name} className="cursor-pointer">
          {label}
        </label>
      </div>
      <ErrorMessage name={field.name} component="div" className="text-red text-sm ml-4" />
    </>
  )

  return isCheckbox ? (
    checkboxComp
  ) : (
    <div className={`${className} flex flex-col`}>
      <span className="text-sm ml-4">{label}</span>
      <input
        {...field}
        type={type}
        className={`border-2 px-4 py-2 rounded-full bg-transparent w-full ${getBorderColor(
          errors,
          field.name
        )}`}
      />
      <ErrorMessage name={field.name} component="div" className="text-red text-sm ml-4" />
    </div>
  )
}

export default Input
