import { createModelFromData, createModelListFromData } from '@mpe/api-client/utils/dataConverter'

import { AppThunk } from '../index'
import {
  createAudioPlaylistError,
  audioPlaylistRequest,
  createAudioPlaylistSuccess,
  deleteAudioPlaylistItemSuccess,
  deleteAudioPlaylistSuccess,
  getAudioPlaylistError,
  getAudioPlaylistAuthenticationError,
  getAudioPlaylistsSuccess,
  updateAudioPlaylistItemSuccess,
  addAudioPlaylistItemSuccess
} from '../slices/audioPlaylistsSlice'
import { getToken } from './videoPlaylistsThunk'

export const getAudioPlaylists = (): AppThunk => async (dispatch) => {
  dispatch(audioPlaylistRequest())

  try {
    const token = getToken()
    if (!token) {
      throw new Error('Token not found')
    }

    const items = await getAudioPlaylistsHttp(token)
    dispatch(getAudioPlaylistsSuccess(items))
    return items
  } catch (error) {
    console.error(error)
    dispatch(getAudioPlaylistError())
  }

  return null
}

export const getAudioPlaylist =
  (id: string): AppThunk =>
  async (dispatch) => {
    dispatch(audioPlaylistRequest())
    try {
      const token = getToken(true)

      const response = await fetch(
        `/api/audio-playlists/${id}`,
        token
          ? {
              headers: {
                'content-type': 'application/json',
                authorization: `Bearer ${token}`
              }
            }
          : undefined
      )

      if (401 === response.status) {
        dispatch(getAudioPlaylistAuthenticationError())
        return
      }

      if (200 !== response.status) {
        throw new Error('Response status is not 200')
      }

      const data = await response.json()
      dispatch(getAudioPlaylistsSuccess([createModelFromData(data.item)]))
    } catch (error) {
      dispatch(createAudioPlaylistError())
    }
  }

export const updateAudioPlaylists =
  (id: string, playlists: string[]): AppThunk =>
  async (dispatch) => {
    dispatch(audioPlaylistRequest())

    try {
      const token = getToken()
      if (!token) {
        throw new Error('Token not found')
      }

      const response = await fetch(`/api/audio-tracks/${id}`, {
        body: JSON.stringify({ playlists }),
        method: 'PUT',
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }
      })

      if (200 !== response.status) {
        throw new Error('Response status is not 200')
      }

      const items = await getAudioPlaylistsHttp(token)
      dispatch(getAudioPlaylistsSuccess(items))

      return true
    } catch (error) {
      console.error(error)
      dispatch(getAudioPlaylistError())
    }

    return false
  }

export const updateEntityAudioPlaylistsRelation =
  (playlistIds: String[], entityId: number): AppThunk =>
  async (dispatch) => {
    dispatch(audioPlaylistRequest())

    try {
      const token = getToken()
      if (!token) {
        throw new Error('Token not found')
      }

      const response = await fetch(`/api/audio-tracks/${entityId}`, {
        method: 'PUT',
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ playlists: playlistIds })
      })

      if (200 !== response.status) {
        throw new Error('Response status is not 200')
      }

      dispatch(addAudioPlaylistItemSuccess(await getAudioPlaylistsHttp(token)))
    } catch (error) {
      console.error(error)
      dispatch(createAudioPlaylistError())
    }
  }

export const createAudioPlaylist =
  (name: string, isPublic = false): AppThunk =>
  async (dispatch, getState) => {
    dispatch(audioPlaylistRequest())

    try {
      const token = getToken()

      const response = await fetch(`/api/audio-playlists`, {
        method: 'POST',
        body: JSON.stringify({ name, isPublic }),
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }
      })

      if (201 !== response.status) {
        throw new Error('Response status is not 201')
      }

      dispatch(createAudioPlaylistSuccess())

      const playlist = await response.json()
      const playlists = [...getState().audioPlaylists.data, createModelFromData(playlist)]

      return { playlist, playlists }
    } catch (error) {
      console.log(error)
      dispatch(createAudioPlaylistError())
    }

    return null
  }

export const updateAudioPlaylist =
  (id: string, name: string, isPublic = false): AppThunk =>
  async (dispatch) => {
    dispatch(audioPlaylistRequest())
    try {
      const token = getToken()

      const response = await fetch(`/api/audio-playlists/${id}`, {
        method: 'PUT',
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ name, isPublic })
      })

      if (200 !== response.status) {
        throw new Error('Response status is not 200')
      }

      dispatch(updateAudioPlaylistItemSuccess({ id, name, isPublic }))

      return true
    } catch (error) {
      console.error(error)
      dispatch(getAudioPlaylistError())
    }

    return null
  }

export const deleteAudioPlaylist =
  (id: string): AppThunk =>
  async (dispatch) => {
    dispatch(audioPlaylistRequest())
    try {
      const token = getToken()

      const response = await fetch(`/api/audio-playlists/${id}`, {
        method: 'DELETE',
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }
      })

      if (204 !== response.status) {
        throw new Error('Response status is not 204')
      }

      dispatch(deleteAudioPlaylistSuccess())
    } catch (error) {
      console.error(error)
      dispatch(getAudioPlaylistError())
    }
  }

export const deleteAudioTrack =
  (id: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(audioPlaylistRequest())

      const token = getToken()

      const response = await fetch(`/api/audio-tracks/${id}`, {
        method: 'DELETE',
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }
      })

      if (204 !== response.status) {
        throw new Error('Response status is not 204')
      }

      dispatch(deleteAudioPlaylistItemSuccess(id))

      return true
    } catch (error) {
      console.log(error)
      dispatch(createAudioPlaylistError())
    }

    return false
  }

export const updateAudioTracksOrder =
  (items: number[]): AppThunk =>
  async (dispatch) => {
    dispatch(audioPlaylistRequest())

    try {
      const token = getToken()
      if (!token) {
        throw new Error('Token not found')
      }

      const response = await fetch('/api/audio-tracks/order', {
        method: 'PUT',
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ items })
      })

      if (200 !== response.status) {
        throw new Error('Response status is not 200')
      }

      const data = await getAudioPlaylistsHttp(token)
      dispatch(getAudioPlaylistsSuccess(data))
    } catch (error) {
      console.error(error)
      dispatch(createAudioPlaylistError())
    }
  }

const getAudioPlaylistsHttp = async (token: string): Promise<any> => {
  const response = await fetch(`/api/audio-playlists`, {
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${token}`
    }
  })

  if (200 !== response.status) {
    throw new Error('Response status is not 200')
  }

  const { data } = await response.json()

  return createModelListFromData(data.items)
}
